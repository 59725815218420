var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "w-screen h-screen absolute top-0 left-0 flex justify-center items-center z-50 layer"
    },
    [
      _c(
        "div",
        {
          staticClass:
            "bg-white w-1/4 min-h-48 rounded-lg shadow-lg flex flex-col"
        },
        [
          _c("h1", {
            staticClass:
              "text-center border-b border-blue-500 font-semibold py-2",
            domProps: { textContent: _vm._s(_vm.header) }
          }),
          _c("p", {
            staticClass:
              "flex-grow flex items-center justify-center text-center px-6 py-8",
            domProps: { textContent: _vm._s(_vm.body) }
          }),
          _c("div", { staticClass: "flex" }, [
            _c("button", {
              staticClass:
                "w-1/2 btn-gray border-t border-blue-500 py-2\n          text-center uppercase py-2 rounded-bl-lg text-red-500",
              domProps: {
                textContent: _vm._s(_vm.$t("general.button_cancel"))
              },
              on: { click: _vm.cancelled }
            }),
            _c("button", {
              staticClass:
                "w-1/2 btn-gray border-t border-blue-500 py-2\n          text-center uppercase py-2 border-l rounded-br-lg text-blue-500",
              domProps: {
                textContent: _vm._s(
                  _vm.confirm || _vm.$t("general.button_confirm")
                )
              },
              on: { click: _vm.confirmed }
            })
          ])
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }