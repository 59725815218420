<template>
  <div class="w-screen h-screen absolute top-0 left-0 flex justify-center items-center z-50 layer">
    <div class="bg-white w-1/4 min-h-48 rounded-lg shadow-lg flex flex-col">
      <h1
        v-text="header"
        class="text-center border-b border-blue-500 font-semibold py-2"></h1>
      <p
        v-text="body"
        class="flex-grow flex items-center justify-center text-center px-6 py-8"></p>
      <div class="flex">
        <button
          v-text="$t('general.button_cancel')"
          class="w-1/2 btn-gray border-t border-blue-500 py-2
            text-center uppercase py-2 rounded-bl-lg text-red-500"
          @click="cancelled"
        ></button>
        <button
          v-text="confirm || $t('general.button_confirm')"
          class="w-1/2 btn-gray border-t border-blue-500 py-2
            text-center uppercase py-2 border-l rounded-br-lg text-blue-500"
          @click="confirmed"
        ></button>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'VConfirm',
    props: {
      header: { type: String, default: '' },
      body: { type: String, default: '' },
      confirm: { type: String, default: null },
    },
    mounted() {
      document.addEventListener('keyup', this.close);
    },
    beforeDestroy() {
      document.removeEventListener('keyup', this.close);
    },
    methods: {
      confirmed() {
        this.$emit('confirm');
      },
      cancelled() {
        this.$emit('cancel');
      },
      close(event) {
        if (event.keyCode !== 27) {
          return;
        }

        this.$emit('cancel');
      },
    },
  };
</script>

<style lang="scss" scoped>
  .layer {
    background-color: rgba(0, 0, 0, 0.4);
  }
</style>
