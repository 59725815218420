<template>
  <div>
    <p
      v-text="$t('general.label_error_occured')"
      class="text-xl pt-4 text-red-500">
    </p>
    <p
      v-text="message"
      class="pb-4 -mt-1 text-md text-gray-900">
    </p>
  </div>
</template>

<script>
  export default {
    name: 'VError',
    props: {
      value: { type: Error, required: true },
    },
    computed: {
      message() {
        return this.value.response
          ? this.value.response.message
          : this.$t('general.label_error');
      },
    },
    created() {
      if (!this.value.response) {
        return;
      }

      if (this.value.response.status === 403) {
        this.$router.push({ name: 'not-authorized' });
      }

      if (this.value.response.status === 404) {
        this.$router.push({ name: 'not-found' });
      }
    },
  };
</script>
